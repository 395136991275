import $ from 'jquery';

export default class Validator {
  static get hostedFieldsErrors() { return {
    'HOSTED_FIELDS_FIELDS_EMPTY': 'Please fill in payment input fields',
    'HOSTED_FIELDS_FIELDS_INVALID': 'Some payment input fields are invalid',
  }};
  static get gerenalErrors() { return {
    'THREEDS_CARDINAL_SDK_ERROR': Validator.paymentTransactionErrorMessage,
  }};
  static get messages() { return {
    bad_email: 'This is not a real email, isn\'t it?',
    invalid_email: 'We couldn\'t verify your email, please double check if it\'s correct. If you are sure it is, please contact us at estimation@lunarlogic.io.',
    empty_field: 'Ekhm... forgot about something?',
    silly_address: 'This address doesn\'t make sense.',
    wrong_quantity: 'Sorry, you can only order quantity between 1 and 100',
    bad_data: 'There\'s something wrong with the field.'
  }};
  static get transactionErrors() { return [
    'processor_declined',
    'settlement_declined',
    'gateway_rejected',
  ]};

  static get paymentTransactionErrorMessage() {
    return 'There was a problem processing your credit card. Please double check your payment information and try again.';
  };
  static get paymentGeneralErrorMessage() {
    return 'Sorry, we encountered some problems. Please try again later or contact us by email.';
  };
  static get paymentInitializationErrorMessage() {
    return 'Sorry, there was some problem with setting up payments provider. Try reloading the page or contact us by email if the error reoccurs.';
  }
  static get paymentGeneralError() { return 'general' };

  constructor() {
    this.errors = {}
  }

  _addError = function(name, message) {
    return this.errors[name] = [message];
  }
  _removeError = function(name, message) {
    if (this.errors[name]) {
      return this.errors[name] = this.errors[name].filter(msg => msg != message);
    }
  }
  getFormField = name => this.form.find(`[name=${name}]`)
  isErrorFor = name => !(this.errors[name] == undefined || this.errors[name].length == 0)

  isFormValid = () => $.isEmptyObject(this.errors)

  setForm(aForm) {
    return this.form = aForm;
  }

  validateNotEmpty = (fieldNames) => {
    return (() => {
      for (let name of Array.from(fieldNames)) {
        if (/^\s*$/.test(this.getFormField(name).val())) {
          this._addError(name, Validator.messages['empty_field']);
        } else {
          this._removeError(name, Validator.messages['empty_field'])
        }
      }
    })();
  }

  validateEmail = (fieldName) => {
    const email = this.getFormField(fieldName).val();
    if (!/^.+@.+\..+$/.test(email)) {
      this._addError(fieldName, Validator.messages['bad_email']);
    } else {
      this._removeError(fieldName, Validator.messages['bad_email']);
      const result = this.validateEmailability(email);
      if (result === 'valid') {
        this._removeError(fieldName, Validator.messages['invalid_email']);
      } else {
        this._addError(fieldName, Validator.messages['invalid_email']);
      }
    }
  }

  validateEmailability = (email) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', '/validate', false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify({'email': email}));
    return xhr.responseText;
  }

  validateAddress(fieldName) {
    const address = this.getFormField(fieldName).val();
    if (!/\d+/.test(address) || !/[a-zA-Z]+/.test(address)) {
      this._addError(fieldName, Validator.messages['silly_address']);
    } else {
      this._removeError(fieldName, Validator.messages['silly_address'])
    }
  }

  validateQuantity = (fieldName) => {
    const qty = parseFloat(this.getFormField(fieldName).val());
    if ((qty !== parseInt(qty,10)) || !(qty > 0) || !(qty <= 100)) {
      return this._addError(fieldName, Validator.messages['wrong_quantity']);
    } else {
      return delete this.errors[fieldName];
    }
  }

  loadTransactionError = (error) => {
    const generalErrorMsg = Validator.transactionErrors.includes(error) ? Validator.paymentTransactionErrorMessage : Validator.paymentGeneralErrorMessage;
    this._addError(Validator.paymentGeneralError, generalErrorMsg);
  }

  loadPaymentMethodError = (error) => {
    const generalErrorMsg = Validator.hostedFieldsErrors[error.code] || error.message;
    this._addError(Validator.paymentGeneralError, generalErrorMsg);
  }

  loadPaymentFormErrors = errors => {
    Object.entries(errors).forEach(error => {
      const [fieldName, fieldInfo] = error;
      if (!fieldInfo.isValid) {
        if (fieldInfo.isEmpty) {
          this._addError(fieldName, Validator.messages['empty_field']);
        } else {
          this._addError(fieldName, this._messageForBadData(fieldName));
        }
      } else {
        return delete this.errors[fieldName];
      };
    })
  }

  loadInitializationError = _error => {
    this._addError(Validator.paymentGeneralError, Validator.paymentInitializationErrorMessage);
  }

  loadVerifyCardError = () => {
    this._addError(Validator.paymentGeneralError, Validator.paymentTransactionErrorMessage);
  }

  _messageForBadData = (fieldName) => {
    const name = fieldName.replace('expiration', '').toLowerCase();
    return Validator.messages['bad_data'].replace('field', name);
  }

  clear() {
    return this.errors = {};
  }

  orderValid = () => {
    this.validateAddress('street');
    this.validateEmail('email');
    this.validateQuantity('quantity');
    this.validateNotEmpty(['email', 'name', 'quantity', 'country', 'street', 'city']);
    return this.isFormValid();
  }
};

angular.module('estimationApp').factory('Validator', () => new Validator());
